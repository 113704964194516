import { Button, Link, ListItemIcon, ListItemText, Typography, } from "@mui/material";
import React, {useEffect, useState, useRef} from "react";
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import IconButton from '@mui/material/IconButton';
import { PosterPlayerCard } from "../components/PosterPlayerCard";
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import DownloadIcon from '@mui/icons-material/Download';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Modal from 'react-bootstrap/Modal';
import { VideoPlayer } from "../components/VideoPlayer";
import OndemandVideoIcon from '@mui/icons-material/OndemandVideo';
import VolumeUpIcon from '@mui/icons-material/VolumeUp';

function MyVerticallyCenteredModal(props) {
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      style={{zIndex:10000}}
    >
      
      <Modal.Body style={{height:400, width:'auto', backgroundColor:'transparent !important'}}>
        <VideoPlayer
        Url={props.VideoUrl}
        />
      </Modal.Body>
     
    </Modal>
  );
}



export const TrainingDetail = (props)=>{
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [modalShow, setModalShow] = React.useState(false);
  const open = Boolean(anchorEl);
  const top = useRef(null)


  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
    return (
        <div>
         <div ref={top} style={{postion:'relative', top:1}}/>
        
       
      <Row>
        <Col xs={12} md={6}>
        <PosterPlayerCard Click={()=>{
          setModalShow(true)
        }} Image={props.Image}/>
        </Col>
        <Col xs={12} md={6}>
        <Typography variant="button" sx={{fontWeight:'bold', fontSize:'2.2vh'}}>{props.CourseName}</Typography>
     <br></br>
     <Typography variant="button">{props.Runtime}</Typography>
     <br></br>
     <IconButton 
      aria-controls={open ? 'basic-menu' : undefined}
      aria-haspopup="true"
      aria-expanded={open ? 'true' : undefined}
      onClick={handleClick}
     >
              <DownloadIcon />
            </IconButton>
            <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        {props.VideoDownloadLink !== '' && (
       <MenuItem onClick={handleClose}>
          <ListItemIcon>
            <OndemandVideoIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText><Link sx={{textDecoration:'none', color:'black'}} target="_blank" download={props.CourseName} href={props.VideoDownloadLink}><Typography variant="button">Download Video</Typography></Link></ListItemText>
          <Typography sx={{marginLeft:'10px !important'}} variant="body2" color="text.secondary">
          <DownloadIcon />
          </Typography>
        </MenuItem>
        )}
                    { props.AudioDownloadLink !== '' && (
        <MenuItem onClick={handleClose}>
          <ListItemIcon>
            <VolumeUpIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText><Link sx={{textDecoration:'none', color:'black'}} target="_blank" download={props.CourseName} href={props.AudioDownloadLink}><Typography variant="button">Download Audio</Typography></Link></ListItemText>
          <Typography sx={{marginLeft:'10px !important'}} variant="body2" color="text.secondary">
          <DownloadIcon />
          </Typography>
        </MenuItem>
    )}
      </Menu>
        </Col>
      </Row>
       <hr></hr>
       <Typography sx={{fontWeight:'bold', fontSize:16}} variant="button">Description</Typography>
       <br></br>
    
        <Typography variant="subtitle1">
         <div dangerouslySetInnerHTML={{__html:props.CourseDescription}}/>

      </Typography>
    <hr></hr>
    <Typography sx={{fontWeight:'bold', fontSize:16}} variant="button">DOCUMENTS</Typography>
    <div style={{display:'flex', flexWrap:'wrap' }}>
       {props.Documents}
      
       </div>
       <hr></hr>
       <MyVerticallyCenteredModal
        VideoUrl={props.VideoUrl}
        show={modalShow}
        onHide={() => setModalShow(false)}
      />
        </div>
    )
}